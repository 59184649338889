import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ColorModeContext, themeSettings} from "./styles/theme";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import IconButton from "@mui/material/IconButton";
import './i18n';
import './App.css';
import RouterConfig from "./navigation/RouterConfig";
function App() {
  const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );
  const theme = React.useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <div>
          <IconButton
          className="button-change-mode"
            style={{
              position: theme.palette.position.default,
              zIndex: "5",
              right: "0",
              top: "17.5vh",
            
            }}
            onClick={colorMode.toggleColorMode}
            color="inherit"
          >
            {theme.palette.mode === "dark" ? (
              <Brightness4Icon style={{color:'#998001'}}/>
            ) : (
              <Brightness7Icon style={{color:'rgb(8, 144, 160)'}}/>
            )}
          </IconButton>
        </div>
        <RouterConfig></RouterConfig>

      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
