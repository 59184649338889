/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/alt-text */
import { ThemeContext } from "@emotion/react";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import Cookies from "js-cookie";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

function SearchBar({
  setSearchQuery,
  hadelGoNewChat,
  value,
  onChange,
  onKeyPress,
}) {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // Prevent the default action to avoid form submission if you're using a form element
      event.preventDefault();
      // Call the function that handles sending the message
      hadelGoNewChat();
    }
    // Note: No need to handle the case for Shift+Enter because a multi-line TextField already supports that behavior by default
  };
  const currentLanguagesCode = Cookies.get("i18next") 
  useEffect(()=>{},[currentLanguagesCode])
  return (
    <form className="form-search">
      <TextField
      className="form-search-inoput"
        style={{
          border:
            theme.palette.mode === "dark"
              ? "1px solid #998001"
              : "1px solid #707070",
          borderRadius: "30px",
          outline: "none",
          color: theme.palette.mode === "dark" ? "#fff" : "#000",
          display:'flex',
          justifyContent:'center'
         
        }}
        id="search-bar"
        value={value} // Use the value prop
        onChange={onChange} // Use the onChange prop
        onKeyPress={handleKeyPress}
        onInput={(e) => {
          setSearchQuery(e.target.value);
        }}
        variant="outlined"
        placeholder={t('Start a new chat')}
        size="small"
        multiline // Set the multiline prop to true for new line support
        rowsMax={0}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {/* <Button> */}
                {/* // eslint-disable-next-line react/jsx-no-comment-textnodes */}
                <Grid
                 onClick={() => hadelGoNewChat()}
                  className="button-search"
                  style={{
                    border:
                      theme.palette.mode === "dark"
                        ? "1px solid #998001"
                        : "1px solid #0890A0",
                    backgroundColor:
                      theme.palette.mode === "dark" ? "#998001" : "#0890A0",
                  }}
                >
               
                  {currentLanguagesCode ==='ar'?(
                    <img
                    src={process.env.PUBLIC_URL + "/Path 1rtl.svg"}
                    style={{ width: "50%", height: "50%" }}
                  />
              
                  ):(
              
                    <img
                    src={process.env.PUBLIC_URL + "/assets/arrow-right.svg"}
                    style={{ width: "50%", height: "50%" }}
                  />
                  )}
                </Grid>
              {/* </Button> */}
            </InputAdornment>
          ),
        }}
      />

      {/* // eslint-disable-next-line react/jsx-no-comment-textnodes */}
    </form>
  );
}
export default SearchBar;
