import { Grid } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react"; // Import useState and useEffect

import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
function ColumnData() {
  const theme = useTheme(); // Access the current theme
  const { t } = useTranslation();
  const data = [
    {
      icons: {
        dark: process.env.PUBLIC_URL +"/assets/icons-dark/spark-icon.svg",
        light: process.env.PUBLIC_URL +"/assets/icons-light/spark-icon.svg",
      },
      title: t('You can ask me about'),
      row_one: t('Classical Arabic Literature & Criticism'),
      row_two: t('Cultural Heritage & History'),
      row_three: t('Folklore & Mythology'),
    },
    {
      icons: {
        dark: process.env.PUBLIC_URL +"/assets/icons-dark/spark-icon.svg",
        light: process.env.PUBLIC_URL +"/assets/icons-light/spark-icon.svg",
      },
      title: t("You can ask me about"),
      row_one: t("Music & Traditional Arts"),
      row_two: t("Maritime Heritage & Sea Narratives"),
      row_three: t("Architectural & Archaeological Heritage"),
    },
    {
      icons: {
        dark: process.env.PUBLIC_URL +"/assets/icons-dark/hand-icon.svg",
        light: process.env.PUBLIC_URL +"/assets/icons-light/hand-icon.svg",
      },
      title: t("You can ask me about"),
      row_one: t("Botany & Agriculture in Tradition"),
      row_two: t(
        "Animal Tales & Symbolism"
      ),
      row_three: t("Cultural Awards & Events"),
    },
  ];

  const data2 = [
    {
      icons: {
        dark: process.env.PUBLIC_URL +"/assets/icons-dark/spark-icon.svg",
        light: process.env.PUBLIC_URL +"/assets/icons-light/spark-icon.svg",
      },
      title: t('You can ask me about'),
      row_one: t('Poetry & Calligraphy'),
      row_two: t('Conservation & Restoration'),
      row_three: t('Dreams & Magic in Culture'),
    },
    {
      icons: {
        dark: process.env.PUBLIC_URL +"/assets/icons-dark/spark-icon.svg",
        light: process.env.PUBLIC_URL +"/assets/icons-light/spark-icon.svg",
      },
      title: t("You can ask me about"),
      row_one: t(
        "Modern Heritage Studies"
      ),
      row_two: t("Cultural Identity & Globalization"),
      row_three: t("Literary Narratives & Storytelling"),
    },
    {
      icons: {
        dark: process.env.PUBLIC_URL +"/assets/icons-dark/hand-icon.svg",
        light: process.env.PUBLIC_URL +"/assets/icons-light/hand-icon.svg",
      },
      title: t("You can ask me about"),
      row_one: t("Fashion & Traditional Costumes"),
      row_two: t(
        "Language & Dialects"
      ),
      row_three: t("Innovation & Creativity"),
    },
  ];
  const [currentData, setCurrentData] = useState(data); // Initial data set
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentData(currentData => currentData === data ? data2 : data);
    }, 5000); // Toggle the data set every 5 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);
  return currentData.map((element, index) => (
    <Grid
      item
      lg={2}
      md={3}
      sm={12}
      key={index}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexWrap:'wrap'
      }}
      className="box-exapamly-chat"
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="box-exapamly-chat-title"
      >
        <div className="box-exapamly-chat-img" >
          {index === 0 && (
          <img
            className="conditional-img0"
            style={{ width: "100%", height: "100%"}}
            src={
              theme.palette.mode === "dark"
                ? element.icons.dark
                : element.icons.light
            }
            alt="Icon"
          />)}
          {index === 1 && (
          <img
            className="conditional-img1"
            style={{ width: "100%", height: "100%" }}
            src={
              theme.palette.mode === "dark"
                ? element.icons.dark
                : element.icons.light
            }
            alt="Icon"
          />)}
        </div>
        {index ===0 && (
        <h5 className="conditional-text0"
        style={{
          color:
            index === 0
              ? theme.palette.mode === "dark"
                ? '#fff'
                : theme.palette.box_one
              : index === 1
              ? theme.palette.mode === "dark"
                ? '#fff'
                : theme.palette.box_two
              : index === 2
              ? theme.palette.mode === "dark"
                ? '#fff'
                : theme.palette.box_three
              : "",
        }}>{element.title}</h5>)}
        {index === 1 && (
        <h5 className="conditional-text1"
        style={{
          color:
            index === 0
              ? theme.palette.mode === "dark"
                ? '#fff'
                : theme.palette.box_one
              : index === 1
              ? theme.palette.mode === "dark"
                ? '#fff'
                : theme.palette.box_two
              : index === 2
              ? theme.palette.mode === "dark"
                ? '#fff'
                : theme.palette.box_three
              : "",
        }}>{element.title}</h5>)}
      </div>
      <Grid
        lg={12}
      md={12}
      sm={12}
      className="box-one"
        style={{
          backgroundColor:
            index === 0
              ? theme.palette.mode === "dark"
                ? theme.palette.box_one
                : theme.palette.box_one
              : index === 1
              ? theme.palette.mode === "dark"
                ? theme.palette.box_two
                : theme.palette.box_two
              : index === 2
              ? theme.palette.mode === "dark"
                ? theme.palette.box_three
                : theme.palette.box_three
              : "",
        }}
      >
        <span style={{color:'#fff', fontSize:'12px'}} >{element.row_one}</span>
      </Grid>
      <Grid
      className="box-one"
        style={{
          backgroundColor:
            index === 0
              ? theme.palette.mode === "dark"
                ? theme.palette.box_one
                : theme.palette.box_one
              : index === 1
              ? theme.palette.mode === "dark"
                ? theme.palette.box_two
                : theme.palette.box_two
              : index === 2
              ? theme.palette.mode === "dark"
                ? theme.palette.box_three
                : theme.palette.box_three
              : "",
        }}
      >
        <span style={{color:'#fff', fontSize:'12px'}}>{element.row_two}</span>
      </Grid>
      <Grid
      className="box-one"
        style={{
          backgroundColor:
            index === 0
              ? theme.palette.mode === "dark"
                ? theme.palette.box_one
                : theme.palette.box_one
              : index === 1
              ? theme.palette.mode === "dark"
                ? theme.palette.box_two
                : theme.palette.box_two
              : index === 2
              ? theme.palette.mode === "dark"
                ? theme.palette.box_three
                : theme.palette.box_three
              : "",
        }}
      >
        <span style={{color:'#fff', fontSize:'12px'}}>{element.row_three}</span>
      </Grid>
    </Grid>
  ));
}

export default ColumnData;
