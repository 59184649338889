// export default Chats;
import React, { useContext, useEffect, useState , useRef } from "react";
import { ThemeContext } from "@emotion/react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material"; // Import Typography from MUI
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import SearchBar from "../../components/Search";
import { t } from "i18next";
import darkSearchIcon from "../../assets/Path 7.svg";
import lightSearchIcon from "../../assets/search.svg";

function Chat() {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  // Function to update the height
  const updateHeight = () => {
    setViewportHeight(window.innerHeight);
  };

  // Effect to set the initial height and add event listeners
  useEffect(() => {
    // Update height on mount
    updateHeight();

    // Add event listener for window resize
    window.addEventListener("resize", updateHeight);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", updateHeight);
  }, []);
  const theme = useContext(ThemeContext);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  useTranslation();
  const iconssearch = {
    dark: darkSearchIcon,
    light: lightSearchIcon,
  };
  const languages = [
    {
      code: "ar",
      name: "العربية",
      country_code: "sa",
      dir: "rtl",
      className: "rtl",
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
      className: "ltr",
    },
  ];
  const currentLanguagesCode = Cookies.get("i18next") ?? "en";
  const currentLanguage = languages.find(
    (l) => l.code === currentLanguagesCode
  );

  // Function to start the chat with a bot message
  const startChat = () => {
    typeBotMessage("Tell me about SIH Chatbot!", messages.length);
  };

  useEffect(() => {
    // This will be called when the component mounts
    startChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [messages, setMessages] = useState([])
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const [messageInput, setMessageInput] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleDeleteMessage = (id) => {
    setMessages((prevMessages) =>
      prevMessages.filter((message) => message?.id !== id)
    );
    setAnchorEl(null);
    setOpenMenuIndex(null);
  };
  const handleAllDeleteMessage = () => {
    setMessages([]);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        // Display a message or toast notification if necessary
        console.log("Text copied to clipboard");
        setAnchorEl(null);
        setOpenMenuIndex(null);
      },
      (err) => {
        console.error("Could not copy text to clipboard", err);
      }
    );
  };
  const hadelGoNewChat = () => {
    if (messageInput.trim()) {
      // Add user message
      const newUserMessage = { text: messageInput, sender: "user" };
      setMessages((messages) => [...messages, newUserMessage]);
      setMessageInput("");

      // Prepare the bot response
      typeBotMessage("Tell me about SIH Chatbot!", messages.length);
    }
  };
  const handleSendMessage = () => {
    if (messageInput.trim()) {
      // Add user message
      const newUserMessage = { text: messageInput, sender: "user" };
      setMessages((messages) => [...messages, newUserMessage]);
      setMessageInput("");

      // Prepare the bot response
      typeBotMessage("Tell me about SIH Chatbot!", messages.length);
    }
  };
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index); // Set index of the clicked message
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null); // Reset the index when closing the menu
  };
  const typeBotMessage = (botMessage, replyToIndex) => {
    let botMessageIndex = 0;
    const nextChar = () => {
      if (botMessageIndex < botMessage.length) {
        setMessages((messages) => {
          // Update only the new bot message, leave the rest of the messages unchanged
          const newMessages = [...messages];
          if (
            !newMessages[replyToIndex + 1] ||
            newMessages[replyToIndex + 1].sender !== "bot"
          ) {
            newMessages[replyToIndex + 1] = { text: "", sender: "bot" };
          }
          newMessages[replyToIndex + 1].text += botMessage[botMessageIndex++];
          return newMessages;
        });
        setTimeout(nextChar, 100); // Wait for 100ms before adding the next character
      }
    };

    // Start typing the bot message after a 1 second delay
    setTimeout(nextChar, 1000);
  };

  useEffect(() => {
    document.body.dir = currentLanguage.dir ?? "ltr";
    document.body.className = currentLanguage.className ?? "ltr";
  });

  const messagesEndRef = useRef(null);

  return (
    <div style={{ height: "100vh",width:"100vw"}}>
      <div
        style={{
          backgroundImage: theme.palette.background.default,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          width: "100vw",
          position: theme.palette.position.default,
          zIndex: 1,
        }}
      ></div>
 
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        className="box-title"
        style={{
          zIndex: 4,
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft:"8vw",
          marginRight:"8vw"
         
        }}
      >
        <Grid item lg={6} md={6} sm={6} style={{ display: "flex" , alignItems:"center"}}>
          <Grid item className="box-title-img">
            <img
              src={process.env.PUBLIC_URL + "/Sharjah_Logo.svg"}
              style={{ zIndex: "1000", width: "100%", height: "50%" , position:"relative", alignItems:"center"}}
              alt="Sharjah_Logo.svg"
            />
          </Grid>
          <Grid>
            <Grid>
              <Typography style={{ zIndex: 4, position:"relative"}} variant="h6">
                SIH Chatbot
              </Typography>
            </Grid>
            <Grid>
              <span
                style={{
                  zIndex: 4,
                  marginTop: "-0.5vh",
                  color: "rgba(153,128,1,0.5)",
                  fontSize: "2vw",
                }}
              >
                {/* Sharjah_AI */}
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={6} className="box-title-search">
          {/* <Grid item lg={4} md={4} sm={4} style={{ width: "50%" }}>
            <form className="form-search-chat">
              <TextField
                id="search-bar"
                className="text"
                onInput={(e) => {}}
                variant="outlined"
                placeholder={t("Search")}
                size="small"
                style={{
                  border:
                    theme.palette.mode === "dark"
                      ? "1px solid #998001"
                      : "1px solid #707070",
                  borderRadius: "30px",
                  color: theme.palette.mode === "dark" ? "#998001" : "#707070",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={
                          theme.palette.mode === "dark"
                            ? iconssearch.dark
                            : iconssearch.light
                        }
                        style={{ zIndex: "1000" }}
                        alt="Search"
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Grid> */}
          <Grid item lg={6} md={6} sm={6}>
            <Button
              onClick={() => {
                handleAllDeleteMessage();
              }}
              className="button-new-chat"
              style={{
                border:
                  theme.palette.mode === "dark"
                    ? "1px solid #998001"
                    : "1px solid #0890A0",
                backgroundColor:
                  theme.palette.mode === "dark" ? "transparent" : "#0890A0",
                // fontSize: "1.3vw",
              }}
            >
              {t("New Chat +")}
            </Button>
          </Grid>
        </Grid>
        
      </Grid>
      <Grid item lg={12} md={12} sm={12} className="box-chat-new">
        <Grid className="box-chat-new-content">
          {messages.map((message, index) => (
            <Box
              key={index}
              className={`box-chat ${
                message?.sender === "bot" ? "user" : "bot"
              }`}
              style={{
                display: "flex",
                justifyContent:
                  message?.sender === "bot" ? "flex-start" : "flex-end",
                flexWrap: "wrap",
              }}
            >
              {message?.sender === "bot" ? (
                <>
                  <Grid
                    item
                    style={{ display: "flex", width: "100%" }}
                    lg={12}
                    md={12}
                    sm={12}
                  >
                    <div
                    className="box-chat-icon"
                      style={{
                        // width: "40%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        style={{
                          display: "flex",
                          gap: "2%",
                        }}
                      >
                        <div style={{ width: "10%" }}>
                          <img
                            src={process.env.PUBLIC_URL + "/Sharjah_Logo.svg"}
                            style={{
                              zIndex: "1000",
                              width: "100%",
                              height: "100%",
                            }}
                            alt="Sharjah_Logo.svg"
                          />
                        </div>
                        <span
                          style={{
                            zIndex: 4,
                            marginTop: "-0.5vh",
                            color: "#998001",
                            fontSize: "10px",
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          SIH Chatbot
                        </span>
                      </Grid>
                      <Grid>
                        {/* <Button
                          aria-controls={`simple-menu-${index}`} // Make the aria-controls unique for each message
                          aria-haspopup="true"
                          onClick={(event) => handleClick(event, index)} // Pass the index here
                        >
                          <img src="More icon.svg" alt="more" />
                        </Button> */}

                        <Menu
                          id={`simple-menu-${index}`} // Make the id unique for each message
                          anchorEl={anchorEl}
                          open={anchorEl && openMenuIndex === index} // Check if this message's menu should be open
                          onClose={() => handleClose()}
                          className="testtt menuopen"
                          PaperProps={{
                            style: {
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? "rgba(106, 106, 106, 0.96)"
                                  : "rgba(255, 255, 255)",
                              border:
                                theme.palette.mode === "dark"
                                  ? "1px solid rgba(106, 106, 106, 0.96)"
                                  : "1px solid #707070",
                              borderRadius: "30px",
                              padding: "1% 5% 1% 0.7% ",
                              boxShadow: "none",
                              // You can add boxShadow, borderRadius, or other styles as needed
                            },
                          }}
                        >
                          <MenuItem
                            className="menuStyle"
                            onClick={() => copyToClipboard(message?.text)}
                          >
                            <img
                              src={
                                theme.palette.mode === "dark"
                                  ? "copy-icon-dark.svg"
                                  : "copy-icon.svg"
                              }
                              alt="edit"
                            />
                            <span
                              style={{
                                color:
                                  theme.palette.mode === "dark"
                                    ? "#fff"
                                    : "#707070",
                                marginLeft: "5%",
                                // You can add boxShadow, borderRadius, or other styles as needed
                              }}
                            >
                              {t("Copy Messssage")}
                            </span>
                          </MenuItem>

                          <MenuItem
                            className="menuStyle"
                            onClick={() => handleDeleteMessage(message?.id)}
                          >
                            <img
                              src={
                                theme.palette.mode === "dark"
                                  ? "recycle-bin-icon-dark.svg"
                                  : "recycle-bin-icon.svg"
                              }
                              alt="delete"
                            />
                            <span
                              style={{
                                color:
                                  theme.palette.mode === "dark"
                                    ? "#fff"
                                    : "#D00880",
                                marginLeft: "5%",
                                // You can add boxShadow, borderRadius, or other styles as needed
                              }}
                            >
                              {t("Delete")}
                            </span>
                          </MenuItem>
                        </Menu>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid itemlg={12} md={12} sm={12} style={{ width: "100%" }}>
                    <Typography
                      style={{
                        margin: "5px 0",
                        padding: "10px",
                        borderRadius: "20px",
                        fontWeight: "bold",
                        backgroundColor:
                          message?.sender === "bot"
                            ? theme.palette.mode === "dark"
                              ? "rgba(112,112,112,0.3)"
                              : "#F0F0F0"
                            : theme.palette.mode === "dark"
                            ? "rgba(112,112,112,0.5)"
                            : "#0890A0",
                        border:
                          message?.sender === "bot"
                            ? theme.palette.mode === "dark"
                              ? "1px solid #998001"
                              : "1px solid #F0F0F0"
                            : theme.palette.mode === "dark"
                            ? "1px solid #998001"
                            : "1px solid #0890A0",
                        color:
                          message?.sender === "bot"
                            ? theme.palette.mode === "dark"
                              ? "white"
                              : "#000"
                            : theme.palette.mode === "dark"
                            ? "1px solid #fff"
                            : "1px solid #fff",
                      }}
                    >
                      {message?.text}
                    </Typography>
                  </Grid>
                </>
              ) : // eslint-disable-next-line eqeqeq
              message?.sender == "user" ? (
                <>
                  {/* <Button
                    aria-controls={`simple-menu-${index}`} // Make the aria-controls unique for each message
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, index)} // Pass the index here
                  >
                    <img src="More icon.svg" alt="more" />
                  </Button> */}

                  <Menu
                    id={`simple-menu-${index}`} // Make the id unique for each message
                    anchorEl={anchorEl}
                    open={anchorEl && openMenuIndex === index} // Check if this message's menu should be open
                    onClose={() => handleClose()}
                    className="testtt menuopen"
                    PaperProps={{
                      style: {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "rgba(106, 106, 106, 0.96)"
                            : "rgba(255, 255, 255)",

                        border:
                          theme.palette.mode === "dark"
                            ? "1px solid rgba(106, 106, 106, 0.96)"
                            : "1px solid #707070",
                        borderRadius: "30px",
                        padding: "1% 5% 1% 0.7% ",
                        boxShadow: "none",
                      },
                    }}
                  >
                    <MenuItem
                      className="menuStyle"
                      onClick={() => copyToClipboard(message?.text)}
                    >
                      <img
                        src={
                          theme.palette.mode === "dark"
                            ? "copy-icon-dark.svg"
                            : "copy-icon.svg"
                        }
                        alt="edit"
                      />
                      <span
                        style={{
                          color:
                            theme.palette.mode === "dark" ? "#fff" : "#707070",
                          marginLeft: "5%",

                          // You can add boxShadow, borderRadius, or other styles as needed
                        }}
                      >
                        {t("Copy Messssage")}
                      </span>
                    </MenuItem>

                    <MenuItem
                      className="menuStyle"
                      onClick={() => handleDeleteMessage(message?.id)}
                    >
                      <img
                        src={
                          theme.palette.mode === "dark"
                            ? "recycle-bin-icon-dark.svg"
                            : "recycle-bin-icon.svg"
                        }
                        alt="delete"
                      />
                      <span
                        style={{
                          color:
                            theme.palette.mode === "dark" ? "#fff" : "#D00880",
                          marginLeft: "5%",
                          // You can add boxShadow, borderRadius, or other styles as needed
                        }}
                      >
                        {t("Delete")}
                      </span>
                    </MenuItem>
                  </Menu>
                  <Typography
                    style={{
                      margin: "5px 0",
                      padding: "10px",
                      borderRadius: "20px",
                      fontWeight: "bold",
                      backgroundColor:
                        message?.sender === "bot"
                          ? theme.palette.mode === "dark"
                            ? "rgba(112,112,112,0.3)"
                            : "#707070"
                          : theme.palette.mode === "dark"
                          ? "rgba(112,112,112,0.5)"
                          : "#0890A0",
                      border:
                        message?.sender === "bot"
                          ? theme.palette.mode === "dark"
                            ? "1px solid #998001"
                            : "1px solid #707070"
                          : theme.palette.mode === "dark"
                          ? "1px solid #998001"
                          : "1px solid #0890A0",
                      color:
                        message?.sender === "bot"
                          ? theme.palette.mode === "dark"
                            ? "#000"
                            : "white"
                          : theme.palette.mode === "dark"
                          ? "1px solid #fff"
                          : "1px solid #fff",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {message?.text}
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Box>
          ))}
          <div ref={messagesEndRef} /> {/* This is the scroll target */}
        </Grid>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        style={{
          display: "flex",
          justifyContent: "center",
          zIndex: 4,
          position: "absolute",
          // width: "100%",
          //bottom: "10px",
          // margin:'0 10%'
        }}
        className="box-add-message-input"
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            onClick={() => {
              handleAllDeleteMessage();
            }}
            className="image-trash"
            src={
              theme.palette.mode === "dark"
                ? "broom-cleaning-icon.svg"
                : "broom-cleaning-icon-light.svg"
            }
            alt="clean"
          />
        </Grid>
        <SearchBar
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
          hadelGoNewChat={hadelGoNewChat}
        />
      </Grid>
    </div>
  );
}

export default Chat;
