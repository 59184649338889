import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import lightBackgroundImage from "../assets/BG.svg";
import darkBackgroundImage from "../assets/Group1.svg";
import darkiconsearch from "../assets/Path 7.svg";
import lighticonsearch from "../assets/search.svg";


// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        white: {
          100: "#ffffff",
          200: "#ffffff",
          300: "#ffffff",
          400: "#ffffff",
          500: "#ffffff",
          600: "#cccccc",
          700: "#999999",
          800: "#666666",
          900: "#333333",
        },
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
          1000: "#0c101b",
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#1F2A40",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#CF0A0A",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
          1000: "#D40005",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
        yellowAccent1: {
          100: "#998001",
        },
        yellowAccent: {
          100: "rgba(113, 103, 56, 0.67)",
        },
        BlueAccent: {
          100: "rgba(35, 87, 121, 0.74)",
        },
        pinkAccent: {
          100: "rgba(102, 51, 92, 0.82)",
        },
      }
    : {
        white: {
          100: "#ffffff",
          200: "#ffffff",
          300: "#ffffff",
          400: "#ffffff",
          500: "#ffffff",
          600: "#cccccc",
          700: "#999999",
          800: "#666666",
          900: "#333333",
        },
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#f2f0f0", // manually changed
          500: "#141b2d",
          600: "#1F2A40",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",

          500: "#CF0A0A",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
          1000: "#D40005",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
        yellowAccent1: {
          100: "#998001",
        },
        yellowAccent: {
          100:"#998001"
        },
        BlueAccent: {
          100:'#0890A0',
        },
        pinkAccent:{
          100:'#707070',
        }
      }),
});

// mui theme settings
// export const themeSettings = (mode) => {
//   const colors = tokens(mode);
//   return {
//     palette: {
//       mode: mode,
//       ...(mode === "dark"
//         ? {
//             // palette values for dark mode
//             primary: {
//               main: colors.primary[500],
//             },
//             secondary: {
//               main: colors.greenAccent[500],
//             },
//             neutral: {
//               dark: colors.grey[700],
//               main: colors.grey[500],
//               light: colors.grey[100],
//             },
//             background: {
//               default: colors.primary[500],
//             },
//           }
//         : {
//             // palette values for light mode
//             primary: {
//               main: colors.primary[100],
//             },
//             secondary: {
//               main: colors.greenAccent[500],
//             },
//             neutral: {
//               dark: colors.re[700],
//               main: colors.grey[500],
//               light: colors.grey[100],
//             },
//             background: {
//               default: "#000",
//             },
//           }),
//     },
//     typography: {
//       fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//       fontSize: 12,
//       h1: {
//         fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//         fontSize: 40,
//       },
//       h2: {
//         fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//         fontSize: 32,
//       },
//       h3: {
//         fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//         fontSize: 24,
//       },
//       h4: {
//         fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//         fontSize: 20,
//       },
//       h5: {
//         fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//         fontSize: 16,
//       },
//       h6: {
//         fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//         fontSize: 14,
//       },
//     },
//   };
// };
export const themeSettings = (mode) => {
  const colors = tokens(mode);

  // Define text colors based on mode
  const textColors = {
    dark: colors.yellowAccent1[100], 
    light: colors.yellowAccent1[100], 
  };
  const boxColors = {
    box_one: {
      dark: colors.BlueAccent[100],
      light: colors.BlueAccent[100],
    },
    box_two: {
      dark: colors.yellowAccent[100],
      light: colors.yellowAccent[100],
    },
    box_three: {
      dark: colors.pinkAccent[100],
      light: colors.pinkAccent[100],
    },
  };

  const backgroundImages = {
    dark: `url(${darkBackgroundImage})`, 
    light: `url(${lightBackgroundImage})`, 
  };
 

  const iconssearch = {
    dark: `url(${darkiconsearch})`, 
    light: `url(${lighticonsearch})`, 
  };
 
  const position = {
    dark: {
      default: "absolute", // Set the default positioning for dark mode
      // Add more positioning properties specific to dark mode if needed
    },
    light: {
      default: "absolute", // Set the default positioning for light mode
      // Add more positioning properties specific to light mode if needed
    },
  };
  const overlayColors = {
    dark: "rgba(0, 0, 0)", 
    light: "rgba(255, 255, 255)", 
  };
  const customStyles = {
    dark: {
      h2: {
        color: colors.yellowAccent1[100], // Replace with your desired color in dark mode
        fontSize: 30, // Replace with your desired font size for h2 in dark mode
        fontWeight: "bold", // Replace with your desired
      },
      h6: {
        color: colors.yellowAccent1[100], // Replace with your desired color in dark mode
        fontSize: 20, // Replace with your desired font size for h2 in dark mode
        fontWeight: "bold", // Replace with your desired
      },
    },
    light: {
      h2: {
        color: colors.yellowAccent1[100], // Replace with your desired color in light mode
        fontSize: 30, // Replace with your desired font size for h2 in light mode
        fontWeight: "bold", // Replace with your desired
      },
      h6: {
        color: colors.yellowAccent1[100], // Replace with your desired color in dark mode
        fontSize: 20, // Replace with your desired font size for h2 in dark mode
        fontWeight: "bold", // Replace with your desired
      },
    },
  };
  return {
    palette: {
      mode: mode,
      // ... other palette settings ...
      text: {
        primary: textColors[mode], // Set text color based on the mode
      },
      background: {
        default: backgroundImages[mode], // Set background image based on the mode
      },
      iconssearch: {
        default: iconssearch[mode], // Set background image based on the mode
      },
      position: {
        default: position[mode].default, // Set positioning based on the mode
        // Add more positioning properties as needed
      },
      overlay: {
        default: overlayColors[mode], // Set overlay color based on the mode
      },
      box_one: boxColors.box_one[mode], // Add box_one background color based on the mode
      box_two: boxColors.box_two[mode], // Add box_two background color based on the mode
      box_three: boxColors.box_three[mode],
      
    },
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        ...customStyles[mode].h2, // Apply custom styles based on the mode
      },
      h3: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        ...customStyles[mode].h6,
      },
    },

    // ... other theme settings ...
  };
};
// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
