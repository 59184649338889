import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "@emotion/react";
import { Grid, Typography } from "@mui/material"; // Import Typography from MUI
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import i18next from "i18next";
import SearchBar from "../../components/Search";
import ColumnData from "../../components/column";
import { useNavigate } from "react-router-dom";
import { Padding } from "@mui/icons-material";

function Home() {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  // Function to update the height
  const updateHeight = () => {
    setViewportHeight(window.innerHeight);
  };

  // Effect to set the initial height and add event listeners
  useEffect(() => {
    // Update height on mount
    updateHeight();

    // Add event listener for window resize
    window.addEventListener('resize', updateHeight);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', updateHeight);
  }, []);
  const theme = useContext(ThemeContext);
  useTranslation();
  const navigate = useNavigate();
  function hadelGoNewChat() {
    navigate("/chat");
  }
  const languages = [
    {
      code: "ar",
      name: "العربية",
      country_code: "sa",
      dir: "rtl",
      className: "rtl",
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
      className: "ltr",
    },
  ];
  const currentLanguagesCode = Cookies.get("i18next") ?? "en";
  const currentLanguage = languages.find(
    (l) => l.code === currentLanguagesCode
  );

  useEffect(() => {
    document.body.dir = currentLanguage.dir ?? "ltr";
    document.body.className = currentLanguage.className ?? "ltr";
  });
  return (
    <div style={{height:'100vh', overflowY:"auto",alignItems:"center"}}>
      <div
        style={{
          backgroundImage: theme.palette.background.default,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          width: "100vw",
          position: theme.palette.position.default,
          zIndex: 2,
        }}
      ></div>

      <div
        style={{
          backgroundColor: theme.palette.overlay.default, // Apply the overlay color
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1, // Set the z-index to 2 for the image background
        }}
      ></div>
      <Grid
      className="box-title-home"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 4,
          alignItems:"center"
          
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/Sharjah_Logo.svg"}
          style={{ zIndex: "1000", position:"relative", alignItems:"center"
        
         }}
          alt="Sharjah_Logo.svg"
          className="logo-home"
        />
        <Typography style={{ marginTop: "1vh", zIndex: 4 , position:"relative", alignItems:"center"}} variant="h2" className="title-logo">
          SIH Chatbot
        </Typography>
      </Grid>
      {/* <Grid
        item
        lg={12}
        md={12}
        sm={12}
        className="ul-div box-change"
        style={{ zIndex: 4, position: "relative" }}
      >
        <div className="ul-item">
        <button
            className="item-dropdown"
            style={{
              background: theme.palette.mode === "dark" ? "#0b0b0bba" : "#fff",
              color: theme.palette.mode === "dark" ? "#fff" : "#0b0b0bba",
              cursor: currentLanguagesCode === "ar" ? "default" : "pointer", // Change cursor based on disabled state
            }}
            onClick={() => i18next.changeLanguage("ar")}
            disabled={currentLanguagesCode === "ar"}
          >
            العربية
          </button>
          <button
            className="item-dropdown"
            style={{
              background: theme.palette.mode === "dark" ? "#0b0b0bba" : "#fff",
              color: theme.palette.mode === "dark" ? "#fff" : "#0b0b0bba",
              cursor: currentLanguagesCode === "en" ? "default" : "pointer", // Change cursor based on disabled state
            }}
            onClick={() => i18next.changeLanguage("en")}
            disabled={currentLanguagesCode === "en"}
          >
            English
          </button>
          
        </div>
      </Grid> */}
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        style={{
    
          display: "flex",
          justifyContent: "center",
          zIndex: 4,
          position: "relative",
          alignItems: 'center'
        }}
        className="box-home-search"
      >
        <SearchBar hadelGoNewChat={hadelGoNewChat} />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        className="box-content-home"
        style={{

          zIndex: 4,
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "1%",
          flexWrap: "wrap",
          alignItems: 'center'
        }}
      >
        <ColumnData />
      </Grid>
    </div>
  );
}

export default Home;
