/* eslint-disable no-unused-vars */

import {
  HOME,
  CHAT,

} from "../navigation/CONSTANTS";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Chats from "../pages/chats";

const RouterConfig = () => {
  return (
    <>

        <Routes>

        <Route exact path={HOME} element={<Home/>} />
          <Route exact path={CHAT} element={<Chats/>} />
 
          
         
        </Routes>
     
    </>
  );
};

export default RouterConfig;
